.chatbox-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 2;
  &.blur {
    z-index: 1;
    background-size: 100%;
    background-position: center;
    filter: blur(8px);
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(256, 256, 256, 0.3);
    }
  }
}
.chatbox-fixed .chatbox-container {
  position: relative;
  top: 0;
  left: calc(50% - 229px);
  width: 458px;
  height: 100%;
  box-shadow: 0 0 20px #ccc;
  border-radius: 30px;
  overflow: hidden;
}
.chatbox-fixed .chatbox-container .thumbnail-container {
  &.blur {
    filter: blur(8px);
  }
}
.chatbox-fixed .pos-rel {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.btn {
  cursor: pointer;
  outline: none;
  padding: 5px 10px;
  margin: 5px;
  font-weight: 600;
  font-size: 16px;
}
.dark-btn {
  background-color: #000;
  color: #fff;
  border: 2px solid #000;
  border-radius: 25px;
  padding: 10px 20px;
  //text-transform: capitalize;
}
.btn:disabled {
  cursor: auto;
  opacity: 0.6;
}
.btn-primary {
}
.dark-btn-rounded {
  background-color: transparent;
  color: #000;
  border: 2px solid #000;
  border-radius: 30px;
  padding: 10px 20px;
}
.btn-rounded {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  padding: 10px 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.video-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (max-width: 480px) {
  .chatbox-fixed .chatbox-container {
    width: 100%;
    left: 0;
    border-radius: 0;
  }
}
// .pill-container {
//   display: flex;
//   flex-wrap: wrap;
// }

// .pill {
//   display: inline-block;
//   padding: 5px 10px;
//   margin: 5px;
//   border-radius: 20px;
//   cursor: pointer;
//   background-color: var(--pill-bg-color);
//   color: var(--pill-text-color);
// }
// .custom-pill {
//   display: inline-block;
//   padding: 5px 10px;
//   margin: 5px;
//   border-radius: 20px;
//   cursor: pointer;
//   background-color: grey;
//   color: white;
//   transition: background-color 0.3s, color 0.3s;
// }
// .pill-container {
//   display: flex;
//   flex-wrap: wrap;
//   position: absolute;
//   top: 15px;
//   left: 150px;
// }
