.video-player {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .video-container {
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .d-j-s {
    justify-content: space-between;
  }
  .video-upper-layer {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: background 0.3s ease-in-out;
    &.bg {
      background: rgba(0, 0, 0, 0.7);
    }
    .home-ic {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }
  }

  .video-controls {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    &.bg {
      position: relative;
      z-index: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
      &::before {
        content: "";
        // background-image: url("../../../public/assets/thumbnail_mb.png");
        z-index: -2;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      &::after {
        content: "";
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
      }
    }
    &:hover {
      opacity: 1 !important;
    }
    &.hide {
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
    .upper {
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px 15px;
      .prev-ic {
        cursor: pointer;
        background-color: #000;
        border-radius: 50%;
        padding-right: 5px;
        border: 2px solid #fff;
      }
    }
    .reply-section {
      width: 100%;
      padding: 10px 0;
      left: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .middle {
    }
    .hide-play {
      opacity: 0;
    }
    .lower {
      position: absolute;
      bottom: 2%;
      left: 0;
      top: auto;
      width: 100%;
      padding: 0 20px;
      .progress-bar {
        justify-content: space-between;
      }
      .volume-sec {
        padding-right: 0;
        position: relative;
        left: 0;
        justify-content: flex-start;
        .speaker-ic {
          padding-right: 0.5rem;
          cursor: pointer;
          position: relative;
          &.mute::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 80%;
            right: 21px;
            top: 2px;
            background-color: #fff;
            transform: rotate(45deg);
          }
        }
        .range {
          width: 0;
        }
        &:hover {
          .range {
            width: 100%;
          }
        }
      }

      .range {
        height: 8px;
        -webkit-appearance: none;
        background: #fff;
        outline: none;
        border-radius: 25px;
        transition: all 0.3s ease-in-out;
        overflow: hidden;
        cursor: pointer;
      }
      .range::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 15px;
        height: 15px;
        background: #ccc;
        cursor: pointer;
        border: 3px solid #515151;
        box-shadow: -407px 0 0 400px #515151;
      }
      input {
        width: 100%;
      }
      .prev-ic {
        cursor: pointer;
        background-color: #000;
        border-radius: 50%;
        padding-right: 5px;
        border: 2px solid #fff;
      }
      .navigation {
        width: 100%;
        float: left;
        margin-top: 10px;
        .btn {
          float: right;
          position: relative;
          padding-right: 30px;
          border-color: #fff;
          &::before {
            content: "";
            position: absolute;
            background-color: #fff;
            width: 2px;
            height: 10px;
            right: 15px;
            transform: rotate(135deg);
            top: 11px;
          }
          &::after {
            content: "";
            position: absolute;
            background-color: #fff;
            width: 2px;
            height: 10px;
            right: 15px;
            transform: rotate(45deg);
            bottom: 11px;
          }
        }
      }
    }
  }
}

.videoList.activePill {
  background: green;
}
.videoList {
  background: red;
}

.custom-pill {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  background-color: grey;
  color: white;
  transition: background-color 0.3s, color 0.3s;
}
.pill-container {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 31px;
  //left: 65px;
  max-width: 350px;
  width: 350px;
}

.pill {
  display: inline-block;

  margin: 5px;
  // cursor: pointer;
  background-color: var(--pill-bg-color);
  color: var(--pill-text-color);

  flex-grow: 1; /* Each pill will take equal space */
  color: white;
  padding: 3px 8px;
  border-radius: 15px;
  margin-right: 10px; /* Adjust spacing between pills */
  margin-bottom: 10px; /* Adjust spacing between rows */
}

// .pill-container {
//   display: grid;
//   grid-template-columns: repeat(
//     auto-fill,
//     minmax(100px, 1fr)
//   ); /* Each pill takes equal space */
//   grid-gap: 10px; /* Adjust spacing between pills */
// }

// .pill {
//   display: flex;
//   justify-content: center; /* Center the content horizontally */
//   align-items: center; /* Center the content vertically */
//   background-color: blue;
//   color: white;
//   padding: 3px 8px;
//   border-radius: 15px;
//   text-align: center; /* Center the content horizontally */
//   width: 100%; /* Make span take the full width of its container */
// }

// .pill-container {
//   display: flex;
//   flex-wrap: nowrap;
// }

// .pill {
//   display: flex;
//   justify-content: center; /* Center the content horizontally */
//   align-items: center; /* Center the content vertically */
//   background-color: blue;
//   color: white;
//   padding: 3px 8px;
//   border-radius: 15px;
//   text-align: center; /* Center the content horizontally */
//   width: 100%; /* Make span take the full width of its container */
//   margin: 5px;
// }
.range {
  height: 8px;
  -webkit-appearance: none;
  background: #fff;
  outline: none;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  cursor: pointer;
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background: #ccc;
  cursor: pointer;
  border: 3px solid #515151;
  box-shadow: -407px 0 0 400px #515151;
}
.status-range {
  max-width: 40px;
  -webkit-appearance: none;
  height: 8px;
  background: #515151;
  outline: none;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.status-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
  background: #fff;
  cursor: pointer;
  border: 3px solid #fff;
  box-shadow: -407px 0 0 400px #fff;
}
@media (max-width: 480px) {
  .video-player .video-controls .lower {
    position: absolute;
    bottom: 0px;
    left: 0;
    top: auto;
    width: 100%;
    padding: 0 20px;
  }
}
