.thumbnail-container {
    height: 100%;
	.thumbnail {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-image: url("../../../public/assets/thumbnail_mb.png");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top;
	}
	.brand-name {
		font-size: 20px;
		color: #fff;
		font-weight: 600;
		padding: 20px;
	}
	.video-player {
		position: absolute;
		z-index: 2;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.7);
	}
	.btn-section {
		position: absolute;
		bottom: 10%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}
	.intro-message {
		position: absolute;
		top: 30%;
		padding: 20px;
		text-align: center;
		width: 100%;
		color: #fff;
		h3 {
			font-size: 35px;
			margin: 10px 0 0;
		}
	}
	@media (max-width: 480px) {
		.intro-message {
			h3 {
				font-size: 30px;
			}
		}
	}
}
