.moderated-quiz{
    .form-control{
        width: 100%;
        margin-bottom: 10px;
        label, input{
            width: 100%;
            outline: none;
            font-size: 14px;
        }
        input{
            margin-top: 5px;
            padding: 8px 10px;
            border: 1px solid #000;
            border-radius: 15px;
        }
        &:last-child{
            margin-bottom: 0;
        }
        .error{
            margin: 5px 0 0 5px;
            color: #ff4848;
        }
    }
}