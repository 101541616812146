.interview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  padding: 20px;
  color: #fff; /* Light text color */
  margin: 50;
}

.participant {
  display: block;
  align-items: center;
  justify-content: center;
  border: 2px solid #333; /* Darker border to fit the dark theme */
  padding: 15px;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 400px;
  background-color: rgb(32 32 32 / 80%); /* Darker gray with transparency */
  color: white; /* Ensure text is readable on dark background */
}

.profile-picture {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.icon-placeholder {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #555; /* Slightly darker gray for the placeholder icon */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #fff; /* White color for the phone icon */
}

.name {
  font-size: 16px;
  color: #fff; /* White text */
}

/* Optional: Add a hover effect */
.participant:hover {
  border-color: #4c4c4c;
  background-color: #3a3a3a;
}
.mic-sonar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px; /* Adjust spacing as needed */
  position: relative;
  z-index: 1;
}

.sonar-emitter {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Dark background for the sonar emitter */
  display: flex;
  align-items: center;
  justify-content: center;
}

.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(256, 256, 256, 0.7); /* Light wave color */
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  animation: sonarWave 2s linear infinite;
}

@keyframes sonarWave {
  0% {
    opacity: 0.4;
    transform: scale(0.5);
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.mic-container {
  background-color: rgba(0, 0, 0, 0.5); /* Dark background */
  border-radius: 50%; /* Circular shape */
  padding: 20px; /* Spacing inside the circle */
  cursor: pointer;
  position: relative; /* Positioning for animations or effects */
  transition: transform 0.3s ease-in-out; /* Add smooth hover effect */
  z-index: 1; /* Ensure it's above the sonar wave */
}

.mic-container:hover {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.mic-text {
  color: white;
  text-align: center;
  margin-top: 10px; /* Spacing between the icon and text */
  font-size: 14px; /* Adjust the font size as needed */
}
