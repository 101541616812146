@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: 'Roboto', sans-serif;
}
* {
	box-sizing: border-box;
}
.text-a-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.d-flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}
p {
	font-size: 16px;
}
input {
	font-size: 16px;
}
.w-100{
	width: 100%;
}
.p-rel{
	position: relative;
}
@media (max-width: 767px) {
	input {
		touch-action: manipulation;
	}
	* {
		-webkit-tap-highlight-color: transparent;
	}
}
