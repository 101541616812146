.dropdown{
    position: relative;
    .dropdown-value{
        margin: 0 0 0 10px;
        font-size: 12px;
        padding: 5px 13px;
        outline: 0;
    }
    .menus{
        position: absolute;
        bottom: 30px;
        right: 1px;
        padding: 0;
        list-style-type: none;
        border: 1px solid #fff;
        margin: 0;
    }
    .menu{
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        padding: 10px 10px;
        font-size: 12px;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
    }
}