.voice-input-sec {
	padding: 0 1.5rem;
	width: 100%;
	.input-group {
		border: 2px solid #fff;
		padding: 0 10px 0 16px;
		border-radius: 30px;
		input {
			width: 100%;
			background-color: transparent;
			outline: 0;
			border: 0;
			color: #fff;
			&.hide{
				opacity: 0;
				visibility: hidden;
			}
		}
		svg {
			cursor: pointer;
		}
		.send-ic {
			margin-right: 10px;
		}
	}
	.btn {
		background-color: transparent;
		&.voice {
			display: flex;
			align-items: center;
			justify-content: space-between;
			overflow: hidden;
			border-radius: 50px;
			padding: 0;
			border: 0;
			svg {
			}
		}
		&.ripple {
			position: relative;
			z-index: 0;
			&::before {
				content: "";
				position: absolute;
				animation: ripple 2s infinite;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background: rgba(256, 256, 256, 0.3);
				left: -12px;
				z-index: 1;
			}
			@keyframes ripple {
				0% {
					transform: scale(0.2);
				}
				25% {
					transform: scale(0.3);
				}
				50% {
					transform: scale(0.5);
				}
				75% {
					transform: scale(0.75);
				}
				100% {
					transform: scale(1.1);
				}
			}
		}
	}
	.loading-sec {
		position: absolute;
		left: 0;
		justify-content: flex-start;
    	padding: 16px;
		.loader-gif {
			text-align: center;
		}
		.loader-gif span {
			display: inline-block;
			vertical-align: middle;
			width: 20px;
			height: 20px;
			background: white;
			border-radius: 20px;
			animation: loader 0.8s infinite alternate;
		}
		.loader-gif span:nth-of-type(2) {
			animation-delay: 0.2s;
		}
		.loader-gif span:nth-of-type(3) {
			animation-delay: 0.6s;
		}
		@keyframes loader {
			0% {
				opacity: 0.9;
				transform: scale(0.5);
			}
			100% {
				opacity: 0.1;
				transform: scale(1);
			}
		}
	}
}
