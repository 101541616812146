.video-play-sec{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0,0,0,0.8);
    .video-play-rel{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .btn-rounded{
        color: #000;
        border-color: #000;
    }
}