.pop-up{
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    .pop-up-container{
        min-width: 85%;
        background: #fff;
        padding: 20px;
        border-radius: 15px;
        .close{
            text-align: right;
        }
    }
    @media(max-width: 767px){
        padding: 16px;
    }
}